import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo, DropdownArrow, Close, ArrowLeft, ArrowUp, ShortLogo } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
    menu: [],
    scrolled: false,
    bottom: false,
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  onScroll = (e) => {
    let { scrolled, bottom } = this.state

    const curScroll = window.scrollY || document.scrollTop
    scrolled = curScroll > 100
    bottom = (window.innerHeight + curScroll) >= document.body.offsetHeight - 200

    requestAnimationFrame(() => {
      this.setState({ scrolled, bottom })
    })
  }

  scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
    this.setState({ menu: [] })
  }

  _toggleOffCanvas = () => {
    typeof document !== 'undefined' && document?.body?.classList?.toggle('menu-active')
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  _toggleSubNav = (key) => {
    let { menu } = this.state
    if (menu[key]) {
      menu[key] = false
    } else {
      for (let i = 0; i < menu.length; i++) {
        menu[i] = false
      }
      menu[key] = true
    }
    this.setState({ menu })
  }

  _toggleSideNav = () => {
    this._toggleOffCanvas()
  }

  render() {

    let { offCanvas, menu, bottom, scrolled } = this.state
    const offices = this.props.offices

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active',
    }

    return (
      <>
        <header className='header'>
          <div className='header__inner'>
            <Link to='/' title='CobdenHayson' className='header__logo' { ...props }>
              <Logo color='#0F2130'/>
            </Link>
            <nav className='header__nav'>
              <ul>
                <li>
                  <Link><span>Buy</span><ArrowUp color='#0F2130'/><DropdownArrow color='#0F2130'/></Link>
                  <ul className={ `sub-nav` }>
                    <li><Link to='/for-sale/' { ...props }>For Sale</Link></li>
                    <li><Link to='/opentimes-sales/' { ...props }>Upcoming inspections</Link></li>
                    <li><Link to='/buy-upcoming-auctions/' { ...props }>Upcoming auctions</Link></li>
                    <li><Link to='/commercial-for-sale/' {...props}>Commercial for sale</Link></li>
                    <li><Link to='/our-neighbourhoods/' { ...props }>Our neighbourhoods</Link></li>
                    <li><Link to='/finance/' {...props}>Finance</Link></li>
                  </ul>
                </li>
                <li>
                  <Link><span>Sell</span><ArrowUp color='#0F2130'/><DropdownArrow color='#0F2130'/></Link>
                  <ul className={ `sub-nav` }>
                    <li><Link to='/sell-with-us/' { ...props }>Sell with us</Link></li>
                    <li><Link to='/sold/' { ...props }>Recent sales</Link></li>
                    <li><Link to='/commercial-sold/' {...props}>Commercial sold</Link></li>
                    <li><Link to='/appraisals/' { ...props }>Request appraisal</Link></li>
                    <li><Link to='/finance/' {...props}>Finance</Link></li>
                  </ul>
                </li>
                <li>
                  <Link><span>Rent</span><ArrowUp color='#0F2130'/><DropdownArrow color='#0F2130'/></Link>
                  <ul className={ `sub-nav` }>
                    <li><Link to='/for-lease/' { ...props }>Rent with us</Link></li>
                    <li><Link to='/opentimes-lease/' { ...props }>Upcoming inspections</Link></li>
                    <li><Link to='/emergency-trades/' { ...props }>Urgent repairs</Link></li>
                    <li><Link to='/management/' { ...props }>Manage with us</Link></li>
                    <li><Link to='/commercial-lease/' {...props}>Commercial for lease</Link></li>
                  </ul>
                </li>
                <li>
                  <Link><span>About</span><ArrowUp color='#0F2130'/><DropdownArrow color='#0F2130'/></Link>
                  <ul className={ `sub-nav` }>
                    <li><Link to='/team/' { ...props }>Our team</Link></li>
                    <li><Link to='/about-us/' { ...props }>About us</Link></li>
                    <li><Link to='/blog/' { ...props }>Blog</Link></li>
                    <li><Link to='/careers/' { ...props }>Careers</Link></li>
                    <li><Link to='/contact/' { ...props }>Contact</Link></li>
                  </ul>
                </li>
                <li>
                  <Link><span>Our Offices</span><ArrowUp color='#0F2130'/><DropdownArrow color='#0F2130'/></Link>
                  <ul className={ `sub-nav` }>
                    { offices?.map((office, i) => (
                      <li key={i}>
                        <Link to={office.uri} { ...props }>{ office.title }</Link>
                      </li>
                    )) }
                  </ul>
                </li>
              </ul>
            </nav>
            <button onClick={ this._toggleOffCanvas } className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines line-1'/>
              <span className='lines line-2'/>
              <span className='lines line-3'/>
            </button>
          </div>
        </header>
        <div className={ `off-canvas ${ offCanvas && 'off-canvas--active' }` }>
          <div className='off-canvas__inner'>
            <div className='off-canvas__header'>
              <Link to='/'>
                <Logo color='#0F2130'/>
              </Link>
              <button onClick={ this._toggleOffCanvas }>
                <Close color='#0F2130'/>
              </button>
            </div>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link onClick={ () => this._toggleSubNav(0) }>Buy</Link></li>
                <li><Link onClick={ () => this._toggleSubNav(1) }>Sell</Link></li>
                <li><Link onClick={ () => this._toggleSubNav(2) }>Rent</Link></li>
                <li><Link onClick={ () => this._toggleSubNav(3) }>About</Link></li>
                <li><Link onClick={ () => this._toggleSubNav(4) }>Contact</Link></li>
              </ul>
            </nav>

            <div className={ menu[0] ? 'off-canvas__cobden active' : 'off-canvas__cobden' }>
              <div className='off-canvas__back'>
                <Link onClick={ () => this._toggleSubNav(0) }>
                  <ArrowLeft color='#0F2130'/>
                  <span>Back</span>
                </Link>
              </div>
              <ul>
                <li><Link to='/for-sale/' { ...props }>For Sale</Link></li>
                <li><Link to='/buy-upcoming-auctions/' { ...props }>Upcoming auctions</Link></li>
                <li><Link to='/opentimes-sales/' { ...props }>Upcoming inspections</Link></li>
                <li><Link to='/commercial-for-sale/' {...props}>Commercial for sale</Link></li>
                <li><Link to='/our-neighbourhoods/' { ...props }>Our neighbourhoods</Link></li>
                <li><Link to='/finance/' {...props}>Finance</Link></li>
              </ul>
            </div>

            <div className={ menu[1] ? 'off-canvas__cobden active' : 'off-canvas__cobden' }>
              <div className='off-canvas__back'>
                <Link onClick={ () => this._toggleSubNav(1) }>
                  <ArrowLeft color='#0F2130'/>
                  <span>Back</span>
                </Link>
              </div>
              <ul>
                <li><Link to='/sell-with-us/' { ...props }>Sell with us</Link></li>
                <li><Link to='/sold/' { ...props }>Recent sales</Link></li>
                <li><Link to='/commercial-sold/' {...props}>Commercial sold</Link></li>
                <li><Link to='/appraisals/' { ...props }>Request appraisal</Link></li>
                <li><Link to='/finance/' {...props}>Finance</Link></li>
              </ul>
            </div>

            <div className={ menu[2] ? 'off-canvas__cobden active' : 'off-canvas__cobden' }>
              <div className='off-canvas__back'>
                <Link onClick={ () => this._toggleSubNav(2) }>
                  <ArrowLeft color='#0F2130'/>
                  <span>Back</span>
                </Link>
              </div>
              <ul>
                <li><Link to='/for-lease/' { ...props }>Rent with us</Link></li>
                <li><Link to='/opentimes-lease/' { ...props }>Upcoming inspections</Link></li>
                <li><Link to='/emergency-trades/' { ...props }>Urgent repairs</Link></li>
                <li><Link to='/management/' { ...props }>Management with CH</Link></li>
                <li><Link to='/for-lease/' { ...props }>Rent</Link></li>
              </ul>
            </div>

            <div className={ menu[3] ? 'off-canvas__cobden active' : 'off-canvas__cobden' }>
              <div className='off-canvas__back'>
                <Link onClick={ () => this._toggleSubNav(3) }>
                  <ArrowLeft color='#0F2130'/>
                  <span>Back</span>
                </Link>
              </div>
              <ul>
                <li><Link to='/team/' { ...props }>Our team</Link></li>
                <li><Link to='/about-us/' { ...props }>About us</Link></li>
                <li><Link to='/blog/' { ...props }>Blog</Link></li>
                <li><Link to='/careers/' { ...props }>Careers</Link></li>
                <li><Link to='/contact/' { ...props }>Contact</Link></li>
                <li><Link to='/about-us/' { ...props }>About</Link></li>
              </ul>
            </div>

            <div className={ menu[4] ? 'off-canvas__cobden active' : 'off-canvas__cobden' }>
              <div className='off-canvas__back'>
                <Link onClick={ () => this._toggleSubNav(4) }>
                  <ArrowLeft color='#0F2130'/>
                  <span>Back</span>
                </Link>
              </div>
              <ul>
                { offices?.map((office, i) => (
                  <li key={i}>
                    <Link to={office.uri} { ...props }>{ office.title }</Link>
                  </li>
                )) }
                <li><Link to='/contact/' { ...props }>Our Offices</Link></li>
              </ul>
            </div>
          </div>
        </div>

        <div className={`
          side-nav 
          ${(scrolled || offCanvas) ? 'side-nav--scrolled' : ''} 
          ${bottom ? 'side-nav--bottom' : ''} 
          ${ offCanvas ? 'side-nav__active' : '' }
        `}>
          <Link to='/'>
            <ShortLogo color='#0F2130'/>
          </Link>

          <button onClick={ this._toggleSideNav }>
            { offCanvas ? 'CLOSE' : 'MENU' }
          </button>
        </div>
        <button className='close-menu' onClick={ this._toggleOffCanvas } />
      </>
    )
  }
}

export default Header
